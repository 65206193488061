import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { eventsLanguages } from '@/i18n/config'
import { getBrowserPrefLang } from '@/modules/utils'

import Article from '../screens/article'

const EventPost = (props) => {
  const article = props.pageContext.article
  const { i18n } = useTranslation()
  useEffect(() => {
    const allAvaLangs = eventsLanguages.map((lang) => lang.code)
    const lang = getBrowserPrefLang(allAvaLangs)
    if (lang && lang !== i18n.language) {
      const path =
        eventsLanguages.find((item) => item.code === lang)?.path ?? ''
      navigate(`/events${path}/${article.slug}`)
    }
  }, [article.lang, article.slug, i18n])
  return <Article {...props} article={article} category="events" />
}

export default EventPost
